import "./About.scss";
import MainImage from "../../components/ui/images/Content.png";
import ArrowImg from "../../components/ui/images/arrow.svg";
import ArrowUpImg from "../../components/ui/images/arrow-up.svg";
import { useState } from "react";

const About = () => {
  const [isOpenArrow1, setIsOpenArrow1] = useState(false);
  const [isOpenArrow2, setIsOpenArrow2] = useState(false);
  const [isOpenArrow3, setIsOpenArrow3] = useState(false);
  const [isOpenArrow4, setIsOpenArrow4] = useState(false);
  const [isOpenArrow5, setIsOpenArrow5] = useState(false);
  const [isOpenArrow6, setIsOpenArrow6] = useState(false);


  return (
    <div className='about container'>
      {/*
      <div className='about__image'>
        <img src={MainImage} alt="main" />
      </div>*/}
      <div className='about__info'>
        <div>
          <h2>О нас</h2>
          <p>Transparency International - это глобальная независимая организация по изучению и борьбе с коррупцией как в международном масштабе, так и в масштабе отдельных стран, одна из целей которой - добиться большей прозрачности и подотчетности власти.
          </p>
       </div>
      </div>
      {/*
      <div className='about__questions'>
        <h3>Частые вопросы</h3>
        <div className='about__blocks'>
          <div className='about__block'>
            <div className='about__item'>
              <details onClick={() => setIsOpenArrow1(!isOpenArrow1)}>
                <summary style={{ listStyle: 'none' }}>
                  Jorem ipsum dolor sit amet, consectetur adipiscing?
                  {isOpenArrow1 ? (<img src={ArrowUpImg} alt="arrow" />) : (<img src={ArrowImg} alt="arrow" />)}
                </summary>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
                </p>
              </details>
            </div>
            <div className='about__item'>
              <details onClick={() => setIsOpenArrow2(!isOpenArrow2)}>
                <summary style={{ listStyle: 'none' }}>
                  Jorem ipsum dolor sit amet, consectetur adipiscing?
                  {isOpenArrow2 ? (<img src={ArrowUpImg} alt="arrow" />) : (<img src={ArrowImg} alt="arrow" />)}
                </summary>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
                </p>
              </details>
            </div>
            <div className='about__item'>
              <details onClick={() => setIsOpenArrow3(!isOpenArrow3)}>
                <summary style={{ listStyle: 'none' }}>
                  Jorem ipsum dolor sit amet, consectetur adipiscing?
                  {isOpenArrow3 ? (<img src={ArrowUpImg} alt="arrow" />) : (<img src={ArrowImg} alt="arrow" />)}
                </summary>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
                </p>
              </details>
            </div>
          </div>
          <div className='about__block'>
            <div className='about__item'>
              <details onClick={() => setIsOpenArrow4(!isOpenArrow4)}>
                <summary style={{ listStyle: 'none' }}>
                  Jorem ipsum dolor sit amet, consectetur adipiscing?
                  {isOpenArrow4 ? (<img src={ArrowUpImg} alt="arrow" />) : (<img src={ArrowImg} alt="arrow" />)}
                </summary>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
                </p>
              </details>
            </div>
            <div className='about__item'>
              <details onClick={() => setIsOpenArrow5(!isOpenArrow5)}>
                <summary style={{ listStyle: 'none' }}>
                  Jorem ipsum dolor sit amet, consectetur adipiscing?
                  {isOpenArrow5 ? (<img src={ArrowUpImg} alt="arrow" />) : (<img src={ArrowImg} alt="arrow" />)}
                </summary>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
                </p>
              </details>
            </div>
            <div className='about__item'>
              <details onClick={() => setIsOpenArrow6(!isOpenArrow6)}>
                <summary style={{ listStyle: 'none' }}>
                  Jorem ipsum dolor sit amet, consectetur adipiscing?
                  {isOpenArrow6 ? (<img src={ArrowUpImg} alt="arrow" />) : (<img src={ArrowImg} alt="arrow" />)}
                </summary>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse
                </p>
              </details>
            </div>
          </div>
        </div>
      </div>
*/}
    </div>
  )
}

export default About