import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
    // PROD:
    baseURL: "https://api.jmd.kg",
    
    // UAT:
    //baseURL: "https://api.law.fishrungames.com",
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
