"use client"
import React from 'react';
import { useEffect, useState } from 'react';

const NetKgTracker = () => {

  const [imghref, setImghref] = useState('');

  useEffect(() => {
    var java="1.3";
    var java1=""+"refer="+escape(document.referrer)+"&page="+escape(window.location.href);
    document.cookie="astratop=1; path=/";
    java1+="&c="+(document.cookie?"yes":"now");
    java1+="&java="+(navigator.javaEnabled()?"yes":"now");
    // java1+="&razresh="+screen.width+'x'+screen.height+"&cvet="+
    // (((navigator.appName.substring(0,3)=="Mic"))?
    // screen.colorDepth:screen.pixelDepth);
    java1+="&jscript="+java+"&rand="+Math.random();
    setImghref("https://www.net.kg/img.php?id=7283&"+java1);

  }, []);

  return (
    <div>
    <a href='https://www.net.kg/stat.php?id=7283&amp;fromsite=7283' target='_blank'>
      <img src={imghref} alt='WWW.NET.KG' width='88' height='31' />
      </a>
    </div>
)

};

export default NetKgTracker;